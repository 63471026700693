<template>
  <b-modal
    id="essilor-save-as-objective-refraction"
    hide-header
    hide-footer
    no-stacking
    centered
    size="lg"
    @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Salvar como</p>
        <Close @click="closeModal" />
      </div>
    </div>
    <hr>
    <div class="bottom">
      <div class="body">
        <div class="radio-container">
          <p>Selecione uma opção para registrar os resultados</p>

          <div>
            <p class="mb-3">Refração objetiva:</p>

            <div class="radio-section">
              <div class="radio">
                <input
                  checked
                  type="radio"
                  name="option_objective_refraction"
                  id="dynamic"
                  value="dynamic"
                  @input="getObjectiveRadioValue"
                >
                <label for="patient_examination">Dinâmica</label>
              </div>

              <div class="radio">
                <input
                  type="radio"
                  name="option_objective_refraction"
                  id="static"
                  value="static"
                  @input="getObjectiveRadioValue"
                >
                <label for="patient_examination">Estática</label>
              </div>
            </div>
          </div>

          <div>
            <p class="mb-3">Refração subjetiva:</p>

            <div class="radio-section">
              <div class="radio">
                <input
                  checked
                  type="radio"
                  name="option_subjective_refraction"
                  id="dynamic"
                  value="dynamic"
                  @input="getSubjectiveRadioValue"
                >
                <label for="patient_examination">Dinâmica</label>
              </div>

              <div class="radio">
                <input
                  type="radio"
                  name="option_subjective_refraction"
                  id="static"
                  value="static"
                  @input="getSubjectiveRadioValue"
                >
                <label for="patient_examination">Estática</label>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="footer">
        <b-button variant="link" @click="closeModal">
          Voltar
        </b-button>
        <div class="btn-group">
          <button class="btn confirm-button" @click="confirmOption">
            Confirmar
          </button>
        </div>
      </div>
    </div>

  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'

export default {
  components: {
    Close
  },
  data() {
    return {
      option_objective: null,
      option_subjective: null,
    }
  },
  props: {
    attendance: Object,
    clinic: Object
  },
  methods: {
    onShow() {
      this.option_objective = 'dynamic'
      this.option_subjective = 'dynamic'
    },
    closeModal() {
      this.$bvModal.hide('essilor-save-as-objective-refraction')
    },
    getObjectiveRadioValue(val) {
      this.option_objective = val.target.value
    },
    getSubjectiveRadioValue(val) {
      this.option_subjective = val.target.value
    },
    async confirmOption() {
      const isLoading = this.$loading.show();
      
      this.api.importEssilorExams({
        patient_id: this.attendance.patient.id,
        attendance_id: this.attendance.id,
        clinic_id: this.clinic.id,
        save_as_objective_refraction: this.option_objective,
        save_as_subjective_refraction: this.option_subjective
      }).then(res => {
        this.$toast.success(res.data.message)
      }).catch(err => {
        this.$toast.error(err.message)
      }).finally(() => {
          isLoading.hide();
          this.closeModal();
          this.$router.go();
        }
      )
    },
  }
}
</script>
<style lang="scss">
#essilor-save-as-objective-refraction {
  font-family: 'Nunito Sans';
  font-style: normal;

  input[type="radio"]:checked {
    accent-color: #305bf2 !important;
  }

  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 800px;

    .modal-body {
      padding: 0;

      .header {
        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
            color: #525C7A;
          }

          svg {
            height: 24px;
            width: 24px;
            fill: #7F91D2;
            cursor: pointer;
          }
        }
      }

      hr {
        margin: 0;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 24px;

        .body {
          .radio-container {
            display: flex;
            flex-direction: column;
            gap: 24px;

            p {
              font-family: 'Red Hat Display';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 134%;
              letter-spacing: -0.01em;
              color: #0C1D59;
            }

            .radio-section {
              display: flex;
              flex-direction: column;
              gap: 16px;

              .radio {
                display: flex;
                align-items: center;
                gap: 12px;

                label {
                  margin: 0;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #525C7A;
                  cursor: pointer;
                }

                input {
                  height: 24px;
                  width: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          hr {
            margin: 24px 0;
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;

          .cancel-button {
            padding: 14px 0;
            font-weight: 600;
            color: var(--error-main, #E02E1D);
            font-size: 14px;
          }

          .confirm-button {
            padding: 14px 16px;
            background: #305BF2;
            border-radius: 8px;
            font-weight: 600;
            color: #FFFFFF;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
